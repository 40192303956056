import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import PrivateLayout from "../../layouts/PrivateLayout";
import ProjectTimeline from "./Projects/Setting/ProjectTimeline";
// Global Page Routes
const Dashboard = React.lazy(() => import("./Dashboard"));
const Projects = React.lazy(() => import("./Projects"));
const Tasks = React.lazy(() => import("./Tasks"));
const Bugs = React.lazy(() => import("./Bugs"));
const Teams = React.lazy(() => import("./Teams/Teams"));
const UserProfile = React.lazy(() => import("./Teams/UserProfile"));
const Sprints = React.lazy(() => import("./Sprints"));
const Recording = React.lazy(() => import("./Recording"));
const NotificationList = React.lazy(() =>
  import("./Notification/NotificationList")
);
const BillingDetail = React.lazy(() => import("./Setting/Billing"));
const Plans = React.lazy(() => import("./Setting/Plans"));
const Preferences = React.lazy(() => import("./Setting/Preferences"));

// Project Routes
const ProjectOverview = React.lazy(() =>
  import("./Projects/ProjectOverView/ProjectOverview")
);
const ProgressBoard = React.lazy(() =>
  import("./Projects/ProgressBoard/ProgressBoard")
);
const ProjectManageStatus = React.lazy(() =>
  import("./Projects/Setting/ProjectManageStatus")
);
const ImportProject = React.lazy(() =>
  import("./Projects/Setting/ImportProject")
);
const Backlog = React.lazy(() => import("./Projects/Backlog/Backlog"));
const ProjectBugs = React.lazy(() => import("./Projects/ProjectBugs"));
const ProjectSprints = React.lazy(() => import("./Projects/ProjectSprints"));
const Files = React.lazy(() => import("./Projects/Files"));
const TestExecution = React.lazy(() =>
  import("./Projects/TestCases/TestExecution")
);
const ViewTestExecution = React.lazy(() =>
  import("./Projects/TestCases/ViewTestExecution")
);
const TestCase = React.lazy(() => import("./Projects/TestCases/TestCase"));
const ProjectCalender = React.lazy(() =>
  import("./Projects/Calender/ProjectCalender")
);
const ManageAccount = React.lazy(() => import("./Setting/ManageAccount"));
const ViewTaskList = React.lazy(() =>
  import("./Projects/Backlog/ViewTaskList")
);
const ViewSprint = React.lazy(() => import("./ViewSprint"));

const PrivatePortal = () => {
  const [progress, setProgress] = useState(0);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [latestSprint, setLatestSprint] = useState("");

  return (
    <React.Suspense fallback={<></>}>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateLayout
              progress={progress}
              setProgress={setProgress}
              notificationsCount={notificationsCount}
              setNotificationsCount={setNotificationsCount}
              latestSprint={latestSprint}
              setLatestSprint={setLatestSprint}
            />
          }
        >
          <Route
            path="/"
            element={
              <React.Suspense fallback={<></>}>
                <Dashboard setProgress={setProgress} />
              </React.Suspense>
            }
          />
          <Route
            path="/dashboard/:panel?/:taskId?/:projectId?"
            element={
              <React.Suspense fallback={<></>}>
                <Dashboard setProgress={setProgress} />
              </React.Suspense>
            }
          />
          <Route
            path="/projects"
            element={
              <React.Suspense fallback={<></>}>
                <Projects setProgress={setProgress} />
              </React.Suspense>
            }
          />
          <Route
            path="/tasks/:panel?/:taskId?/:projectId?"
            element={
              <React.Suspense fallback={<></>}>
                <Tasks setProgress={setProgress} />
              </React.Suspense>
            }
          />
          <Route
            path="/bugs/:panel?/:taskId?/:projectId?"
            element={
              <React.Suspense fallback={<></>}>
                <Bugs setProgress={setProgress} />
              </React.Suspense>
            }
          />
          <Route
            path="/recording/:panel?/:fileId?"
            element={
              <React.Suspense fallback={<></>}>
                <Recording setProgress={setProgress} />
              </React.Suspense>
            }
          />
          <Route
            path="/teams"
            element={
              <React.Suspense fallback={<></>}>
                <Teams setProgress={setProgress} />
              </React.Suspense>
            }
          />
          <Route
            path="/teams/user-profile/:user_id/:panel?/:taskId?/:projectId?"
            element={
              <React.Suspense fallback={<></>}>
                <UserProfile setProgress={setProgress} />
              </React.Suspense>
            }
          />
          <Route
            path="/sprints/:panel?/:taskId?/:projectId?"
            element={
              <React.Suspense fallback={<></>}>
                <Sprints setProgress={setProgress} />
              </React.Suspense>
            }
          />
          <Route
            path="/project/:id/overview/:panel?/:taskId?/:projectId?"
            element={
              <React.Suspense fallback={<></>}>
                <ProjectOverview setProgress={setProgress} />
              </React.Suspense>
            }
          />
          <Route
            path="/project/:id/bugs/:panel?/:taskId?/:projectId?"
            element={
              <React.Suspense fallback={<></>}>
                <ProjectBugs setProgress={setProgress} />
              </React.Suspense>
            }
          />
          <Route
            path="/project/:id/backlog/:panel?/:taskId?/:projectId?"
            element={
              <React.Suspense fallback={<></>}>
                <Backlog setProgress={setProgress} />
              </React.Suspense>
            }
          />
          <Route
            path="/project/:id/taskList/:listId/:panel?/:taskId?/:projectId?"
            element={
              <React.Suspense fallback={<></>}>
                <ViewTaskList setProgress={setProgress} />
              </React.Suspense>
            }
          />{" "}
          <Route
            path="/project/:id/viewsprint/:viewSprintId/:panel?/:taskId?/:projectId?"
            element={
              <React.Suspense fallback={<></>}>
                <ViewSprint
                  setProgress={setProgress}
                  setLatestSprint={setLatestSprint}
                />
              </React.Suspense>
            }
          />
          <Route
            path="/project/:id/sprints/:panel?/:taskId?/:projectId?"
            element={
              <React.Suspense fallback={<></>}>
                <ProjectSprints setProgress={setProgress} />
              </React.Suspense>
            }
          />
          <Route
            path="/project/:id/testExecution/:testExecutionId?/:panel?/:testCaseId?/:projectId?"
            element={
              <React.Suspense fallback={<></>}>
                <TestExecution setProgress={setProgress} />
              </React.Suspense>
            }
          />
          <Route
            path="/project/:id/viewTestExecution/:testExecutionId/:panel?/:testCaseId?/:projectId?"
            element={
              <React.Suspense fallback={<></>}>
                <ViewTestExecution setProgress={setProgress} />
              </React.Suspense>
            }
          />
          <Route
            path="/project/:id/testCase/:panel?/:testCaseId?/:projectId?"
            element={
              <React.Suspense fallback={<></>}>
                <TestCase setProgress={setProgress} />
              </React.Suspense>
            }
          />
          <Route
            path="/project/:id/manageStatus"
            element={
              <React.Suspense fallback={<></>}>
                <ProjectManageStatus setProgress={setProgress} />
              </React.Suspense>
            }
          />
          <Route
            path="/project/:id/import"
            element={
              <React.Suspense fallback={<></>}>
                <ImportProject setProgress={setProgress} />
              </React.Suspense>
            }
          />
          <Route
            path="/project/:id/timeline"
            element={
              <React.Suspense fallback={<></>}>
                <ProjectTimeline setProgress={setProgress} />
              </React.Suspense>
            }
          />
          <Route
            path="/project/:id/progress/:panel?/:taskId?/:projectId?"
            element={
              <React.Suspense fallback={<></>}>
                <ProgressBoard
                  setProgress={setProgress}
                  setLatestSprint={setLatestSprint}
                />
              </React.Suspense>
            }
          />
          <Route
            path="/project/:id/files/:panel?/:taskId?/:projectId?"
            element={
              <React.Suspense fallback={<></>}>
                <Files setProgress={setProgress} />
              </React.Suspense>
            }
          />
          <Route
            path="/setting/manageaccount"
            element={
              <React.Suspense fallback={<></>}>
                <ManageAccount />
              </React.Suspense>
            }
          />{" "}
          <Route
            path="/setting/plans"
            element={
              <React.Suspense fallback={<></>}>
                <Plans setProgress={setProgress} />
              </React.Suspense>
            }
          />{" "}
          <Route
            path="/setting/preferences"
            element={
              <React.Suspense fallback={<></>}>
                <Preferences setProgress={setProgress} />
              </React.Suspense>
            }
          />
          <Route
            path="/setting/billing"
            element={
              <React.Suspense fallback={<></>}>
                <BillingDetail setProgress={setProgress} />
              </React.Suspense>
            }
          />
          <Route
            path="/project/:id/calender/:panel?/:taskId?/:projectId?"
            element={
              <React.Suspense fallback={<></>}>
                <ProjectCalender />
              </React.Suspense>
            }
          />
          <Route
            path="/notifications/:panel?/:taskId?/:projectId?"
            element={
              <React.Suspense fallback={<></>}>
                <NotificationList
                  notificationsCount={notificationsCount}
                  setNotificationsCount={setNotificationsCount}
                />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </React.Suspense>
  );
};

export default PrivatePortal;

import {
  faBars,
  faBell,
  faClose,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { editProject, projectLists } from "../Redux/Slice/Projects";
import { getTimeAgo } from "../Utils/helpers";
import SmallLogo from "../assets/images/Logo icon.svg";
import { api } from "../customAxios";
import TaskModal from "../pages/Private/Projects/Tasks/TaskModal";
import ProjectModalWindow from "./comman/Projects/ProjectModalWindow";
import Search from "./comman/Search";
import CustomAvatar from "./comman/Task/CustomAvatar";
import ExportProjectModal from "../pages/Private/Projects/Setting/ExportProjectModal";

function Navbar(props) {
  const {
    notificationsCount,
    setNotificationsCount,
    latestSprint,
    setLatestSprint,
  } = props;
  const [openProjectModalWindow, setOpenProjectWindow] = useState(false);
  const location = useLocation();
  const { id, taskId, fileId, listId, viewSprintId } = useParams();
  const showProjectNav = location.pathname.includes("/user/project/");
  const [showItems, setShowItems] = useState(true);
  const [user, setUser] = useState();
  const [isOpenTaskModelWindow, setIsOpenTaskModelWindow] = useState(false);
  const [taskType, setTaskType] = useState("");
  const [project, setProject] = useState();
  const [projectTaskLists, setProjectTaskLists] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isOpenExportProject, setIsOpenExportProject] = useState(false);

  const prevIdRef = useRef();

  useEffect(() => {
    if (id && id !== prevIdRef.current) {
      projectView();
      prevIdRef.current = id;
    }
    setShowItems(true);
  }, [id, location.pathname]);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 902) {
        setIsNavOpen(true);
      } else {
        setIsNavOpen(false);
      }
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
    getAllNotifications();
  }, [localStorage.getItem("user")]);
  const projectView = () => {
    if (!location.pathname.includes("user-profile")) {
      dispatch(editProject(id)).then((response) => {
        setProject(response.payload);
        setLatestSprint({
          id: response?.payload?.active_sprint_id,
        });
      });
      dispatch(projectLists(id)).then((resp) => {
        setProjectTaskLists(resp.payload);
      });
    }
  };
  const handleCreateTask = async (data, taskList) => {
    await api
      .post("/tasks", data)
      .then((response) => {
        toast.success("Task created successfully!");
        const data = {
          newTask: response.data.task,
          tasklist_id: taskList,
        };
        navigate(location.pathname, { state: { data } });
        setIsOpenTaskModelWindow(false);
      })
      .catch(function (error) {
        setIsOpenTaskModelWindow(false);
      });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getAllNotifications();
    }, 40000);
    return () => clearInterval(intervalId);
  }, []);
  const getAllNotifications = () => {
    api
      .get("/user-un-read-notifications")
      .then((response) => {
        setNotifications(response.data.notifications);
        setNotificationsCount(response.data.totalUnreadCount);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const handleViewTask = (notification) => {
    window.open(notification.link, "_self");
  };
  const handleReadNotification = (e, type, id) => {
    e.stopPropagation();
    if (notificationsCount === 0) {
      toast.info("There is no unread notification.");
      return;
    }
    let notificationIds = [];
    notificationIds = [id];
    props.setProgress(30);
    const payload = new FormData();
    if (type === "single") {
      notificationIds.forEach((id, index) => {
        payload.append(`notification_id[${index}]`, id);
      });
    }
    payload.append("read", type === "all" ? 1 : 0);
    localStorage.removeItem("markedNotification");
    api
      .post(`/notifications/read`, payload)
      .then((response) => {
        props.setProgress(60);
        localStorage.setItem("markedNotification", type === "all" ? type : id);
        if (type === "all") {
          setNotifications([]);
          setNotificationsCount(0);
        } else {
          refreshNotification();
          setNotificationsCount(notificationsCount - 1);
        }
        toast.success(
          `${
            type == "all" ? "All notifications" : "Notification"
          } marked as read.`
        );
      })
      .finally(() => {
        props.setProgress(100);
      });
  };
  const inPutVisible = () => {
    setShowItems(!showItems);
  };
  const moveToProjectDestination = (destination) => {
    navigate(`/user/project/${id}/${destination}`);
  };
  const handleExportProject = () => {
    setIsOpenExportProject(true);
  };

  const handleOpenTaskWindow = (type) => {
    setTaskType(type);
    setIsOpenTaskModelWindow(true);
  };
  const logout = () => {
    localStorage.clear();
  };
  const handleCreateProject = () => {
    const jsonString = localStorage.getItem("permissions");
    const jsonObject = JSON.parse(jsonString);
    if (
      jsonObject?.projects?.limit_value != jsonObject?.projects?.limit_count
    ) {
      setOpenProjectWindow(true);
    } else {
      toast.info(`Your projects limit has been exceeded.`);
    }
  };
  const refreshNotification = () => {
    const selectedId = localStorage.getItem("markedNotification");
    const remainNotifications = notifications?.filter(
      (notification) => notification.id !== selectedId
    );
    setNotifications(remainNotifications);
  };
  useEffect(() => {
    refreshNotification();
  }, [localStorage.getItem("markedNotification")]);
  return (
    <div className="project_navbar">
      <nav className="navbar navbar-expand-lg fixed-top page-navbar bg-body-tertiary p-x-y ">
        <div className={`container-fluid`}>
          <div className="navbar-brand__my">
            <Link to="/user/dashboard" className="navbar-brand">
              <img id="fullLogo" src={process.env.REACT_APP_BRAND_LOGO} />
              <img id="logoIcon" src={SmallLogo} />
            </Link>

            <button
              className="navbar-toggler bar-icon_btn toggle-margin"
              type="button"
              aria-controls="navbarScroll"
              aria-expanded={isNavOpen ? "true" : "false"}
              aria-label="Toggle navigation"
              onClick={() => {
                setIsNavOpen(!isNavOpen);
              }}
            >
              {/* <i className="icon-reorder"></i> */}
              <FontAwesomeIcon icon={isNavOpen ? faBars : faClose} />
            </button>
          </div>
          <div
            className={`collapse navbar-collapse navbar-collapse__my ${
              isNavOpen ? "d-none" : "show"
            }`}
          >
            <ul
              className={`navbar-nav my-2 project-ul navbar-nav-scroll ${
                showProjectNav ? "" : "global-search-main"
              }`}
            >
              {showProjectNav ? (
                <>
                  <li
                    className={`nav-item project_search_li ${
                      showItems ? "d-none" : ""
                    }`}
                    style={{ width: "95%" }}
                  >
                    <Search />
                  </li>
                  <div
                    className={`${
                      showItems ? "d-flex align-items-center ml-6px" : "d-none"
                    }`}
                  >
                    <Link to={`/user/project/${id}/overview`}>
                      <li
                        className={`nav-item ${
                          location.pathname === `/user/project/${id}/overview`
                            ? "active"
                            : ""
                        }`}
                      >
                        <a>Overview</a>
                      </li>
                    </Link>
                    <Link
                      to={`/user/project/${id}/progress`}
                      className="nav_progress"
                    >
                      <li
                        className={`nav-item ${
                          location.pathname === `/user/project/${id}/progress`
                            ? "active"
                            : ""
                        }`}
                      >
                        <a>Progress board</a>
                      </li>
                    </Link>
                    <Link to={`/user/project/${id}/backlog`}>
                      <li
                        className={`nav-item ${
                          location.pathname === `/user/project/${id}/backlog` ||
                          location.pathname ===
                            `/user/project/${id}/taskList/${listId}`
                            ? "active"
                            : ""
                        }`}
                      >
                        <a>Backlog</a>
                      </li>
                    </Link>
                    <Link to={`/user/project/${id}/bugs`}>
                      <li
                        className={`nav-item ${
                          location.pathname === `/user/project/${id}/bugs` ||
                          location.pathname ===
                            `/user/project/${id}/bugs/${taskId}/viewbug`
                            ? "active"
                            : ""
                        }`}
                      >
                        <a>Bugs</a>
                      </li>
                    </Link>
                    <Link>
                      <li className="nav-item">
                        <Dropdown className="navSettingDropDown">
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            className={`button_style  ${
                              location.pathname
                                .split("/")[4]
                                ?.toLowerCase()
                                .includes("test")
                                ? "btn_active"
                                : ""
                            }`}
                          >
                            <Link className="QA-nav-item">
                              QA
                              <KeyboardArrowDownIcon className="dropIcon" />
                            </Link>
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            className="navCustomMenu"
                            placement="bottom-end"
                          >
                            <Dropdown.Item
                              className={`menuItem ${
                                location.pathname ===
                                `/user/project/${id}/testCase`
                                  ? "add_bg"
                                  : ""
                              }`}
                              onClick={() =>
                                moveToProjectDestination("testCase")
                              }
                            >
                              Test Case
                            </Dropdown.Item>
                            <Dropdown.Item
                              className={`menuItem ${
                                location.pathname ===
                                `/user/project/${id}/testExecution`
                                  ? "add_bg"
                                  : ""
                              }`}
                              onClick={() =>
                                moveToProjectDestination("testExecution")
                              }
                            >
                              Test Execution
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                    </Link>
                    <Link to={`/user/project/${id}/sprints`}>
                      <li
                        className={`nav-item ${
                          location.pathname === `/user/project/${id}/sprints` ||
                          location.pathname ===
                            `/user/project/${id}/viewsprint/${viewSprintId}`
                            ? "active"
                            : ""
                        }`}
                      >
                        <a>Sprints</a>
                      </li>
                    </Link>
                    <Link to={`/user/project/${id}/files`}>
                      <li
                        className={`nav-item ${
                          location.pathname === `/user/project/${id}/files` ||
                          location.pathname ===
                            `/user/project/${id}/view/${fileId}/file`
                            ? "active"
                            : ""
                        }`}
                      >
                        <a>Files</a>
                      </li>
                    </Link>
                    <Link to={`/user/project/${id}/calender`}>
                      <li
                        className={`nav-item ${
                          location.pathname === `/user/project/${id}/calender`
                            ? "active"
                            : ""
                        }`}
                      >
                        <a>Calender</a>
                      </li>
                    </Link>
                    <Link>
                      <li className="nav-item">
                        <Dropdown className="navSettingDropDown">
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            className={`button_style project_setting_btn ${
                              location.pathname ===
                                `/user/project/${id}/setting` ||
                              location.pathname ===
                                `/user/project/${id}/manageStatus` ||
                              location.pathname === `/user/project/${id}/import`
                                ? "btn_active"
                                : ""
                            }`}
                          >
                            <Link>
                              Settings
                              <KeyboardArrowDownIcon className="dropIcon" />
                            </Link>
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            className="navCustomMenu"
                            placement="bottom-end"
                          >
                            <Dropdown.Item
                              className={`menuItem ${
                                location.pathname ===
                                `/user/project/${id}/manageStatus`
                                  ? "add_bg"
                                  : ""
                              }`}
                              onClick={() =>
                                moveToProjectDestination("manageStatus")
                              }
                            >
                              Manage Status
                            </Dropdown.Item>
                            <Dropdown.Item
                              className={`menuItem ${
                                location.pathname ===
                                `/user/project/${id}/import`
                                  ? "add_bg"
                                  : ""
                              }`}
                              onClick={() =>
                                moveToProjectDestination("timeline")
                              }
                            >
                              TimeLine
                            </Dropdown.Item>
                            <Dropdown.Item
                              className={`menuItem ${
                                location.pathname ===
                                `/user/project/${id}/import`
                                  ? "add_bg"
                                  : ""
                              }`}
                              onClick={() => moveToProjectDestination("import")}
                            >
                              Import Project
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="menuItem"
                              onClick={handleExportProject}
                            >
                              Export Project
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                    </Link>
                  </div>
                  <li
                    className="nav-item project-nav d-flex align-items-center"
                    onClick={() => inPutVisible()}
                  >
                    {showItems ? (
                      <FontAwesomeIcon
                        icon={faSearch}
                        className="search"
                        style={{ paddingLeft: "14px" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="search uni_search_close_icon"
                      />
                    )}
                  </li>
                </>
              ) : (
                <>
                  <li
                    className="nav-item project-nav global-search-input"
                    onClick={() => inPutVisible()}
                  >
                    <Search />
                  </li>
                </>
              )}
            </ul>
            <div className="d-flex nav-right nav-right-reponsive justify-content-between">
              <div className="nav-option">
                {location.pathname.startsWith("/user/project/") ? (
                  <Dropdown className="navCustomDropDown">
                    <Dropdown.Toggle
                      className={`btn btn-primary btn-sm navCreateTaskBtn ${
                        !project?.userPermission?.can_i_edit &&
                        "not-allowed-disable"
                      }`}
                      // disabled={!project?.userPermission?.can_i_edit}
                    >
                      <span>Create</span>
                      <KeyboardArrowDownIcon className="dropIcon " />
                    </Dropdown.Toggle>
                    {project?.userPermission?.can_i_edit && (
                      <Dropdown.Menu
                        className="navCustomMenu"
                        placement="bottom-end"
                      >
                        <Dropdown.Item
                          onClick={() => handleOpenTaskWindow("E")}
                        >
                          Create User Story
                        </Dropdown.Item>

                        <Dropdown.Item
                          onClick={() => handleOpenTaskWindow("T")}
                        >
                          Create Task
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => handleOpenTaskWindow("B")}
                        >
                          Create Bug
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    )}
                  </Dropdown>
                ) : (
                  <button
                    className="btn btn-primary btn-sm btn_align_create"
                    onClick={() => {
                      handleCreateProject();
                    }}
                  >
                    Create
                  </button>
                )}
              </div>
              <ul className="navbar-nav my-2 my-lg-0 navbar-nav-scroll">
                <Dropdown
                  className={`nav-drop-notification ${
                    location.pathname === "/user/notifications"
                      ? "active-notification"
                      : ""
                  }`}
                >
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="button_style button_padding"
                  >
                    <li className="nav-item nav-set">
                      {notificationsCount > 0 && (
                        <span
                          className={`notification-count ${
                            notificationsCount > 99 ? "countPlus" : ""
                          }`}
                        >
                          {notificationsCount > 99 ? "99+" : notificationsCount}
                        </span>
                      )}
                      <FontAwesomeIcon
                        icon={faBell}
                        className="nocitification-icon"
                      />
                    </li>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="notification-menus">
                      {notifications.length >= 1 && notificationsCount > 0 ? (
                        notifications.map((notification, index) => {
                          return (
                            <Dropdown.Item key={index + 1}>
                              <li className="nav-notification">
                                <div
                                  className={`left-section d-flex gap-2 align-items-center ${
                                    index === notifications?.length - 1
                                      ? "border-0"
                                      : ""
                                  }`}
                                >
                                  <div
                                    className={`view_task_icon m-0 ${
                                      notification?.task_type === "T" &&
                                      notification?.parent_id === null
                                        ? "task-done-blue"
                                        : notification?.task_type === "B" &&
                                          notification?.parent_id === null
                                        ? "bug"
                                        : notification?.task_type === "T" &&
                                          notification.parent_id !== null
                                        ? "task-done-yellow"
                                        : notification?.task_type === "E"
                                        ? "userStory"
                                        : notification.task_type === null
                                        ? "project-icon"
                                        : "bug"
                                    }`}
                                  ></div>
                                  <div
                                    className="notification-text gap-1"
                                    onClick={() => handleViewTask(notification)}
                                  >
                                    <span className="fs-12 text-capitalize textEllipse">
                                      {notification?.project_name}
                                    </span>
                                    <span className="assignee ">
                                      {JSON.parse(notification.data).body.slice(
                                        0,
                                        35
                                      )}
                                      {JSON.parse(notification.data).body
                                        .length > 35
                                        ? "..."
                                        : ""}
                                    </span>
                                    <span className="notification-time">
                                      {getTimeAgo(notification?.created_at)}
                                    </span>
                                  </div>
                                  <div
                                    className="close-icon"
                                    onClick={(e) =>
                                      handleReadNotification(
                                        e,
                                        "single",
                                        notification.id
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon icon={faClose} />
                                  </div>
                                </div>
                              </li>
                            </Dropdown.Item>
                          );
                        })
                      ) : (
                        <div className="no-notification-msg p-20">
                          <span className="font-weight-600 f-14">
                            No un-read notifications
                            <br />
                            <span className="small-notification-msg">
                              You're up-to-date
                            </span>
                          </span>
                        </div>
                      )}
                    </div>
                    <div className={`notification-viewAll notification-no`}>
                      {/* <Divider /> */}
                      <Dropdown.Item className="mb-10 mark-notification-action">
                        <li className="nav-notification d-flex justify-content-between align-items-center">
                          <Link to="/user/notifications" className="view-more">
                            View all notifications
                          </Link>
                          <Link
                            onClick={(e) => handleReadNotification(e, "all")}
                            className="f-12"
                          >
                            Mark all as read
                          </Link>
                        </li>
                      </Dropdown.Item>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <div className="user dropdown">
                  <a
                    className="btn dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <li className="nav-item nav-set">
                      {user?.image ? (
                        <div
                          className="avatar__border size_cover profile_img border_radius10"
                          style={{
                            backgroundImage: `url('${user.image}')`,
                          }}
                        ></div>
                      ) : (
                        <CustomAvatar
                          backgroundColor={user?.profile_color || ""}
                          name={user?.name || ""}
                          height={47}
                          width={47}
                          fontSize={20}
                          borderRadius={10}
                        />
                      )}
                    </li>
                  </a>
                  <ul className="dropdown-menu drops">
                    <div className="text-center mt-3">
                      {user?.image ? (
                        <div className="avatar__container">
                          <div
                            className="avatar__border size_cover profile_img_height border_radius10 background__even"
                            style={{
                              backgroundImage: `url('${user.image}')`,
                            }}
                          ></div>
                        </div>
                      ) : (
                        <div className="avatar__container">
                          <CustomAvatar
                            backgroundColor={user?.profile_color || ""}
                            name={user?.name || ""}
                            height={64}
                            width={64}
                            fontSize={22}
                            borderRadius={10}
                          />
                        </div>
                      )}

                      <p className="mt-2 mb-3 textEllipse text-capitalize">
                        {user?.name}
                      </p>
                    </div>
                    {/* <Link to={`/user/teams/user-profile/${user?.id}`}>
                      <button className="btn btn-sm btn-light w-75 ms-4 mt-2">
                        View Profile
                      </button>
                    </Link> */}
                    <Link to="/user/setting/manageaccount">
                      <button className="btn btn-sm btn-cancel w-75 ms-4 ">
                        Edit Profile
                      </button>
                    </Link>
                    <Link to="/signin">
                      <button
                        className="btn btn-sm btn-cancel w-75 ms-4 mt-3 mb-3"
                        onClick={logout}
                      >
                        Log Out
                      </button>
                    </Link>
                  </ul>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <hr className="nav-hr" />
      <ProjectModalWindow
        open={openProjectModalWindow}
        setOpen={setOpenProjectWindow}
        isEditProject={false}
        projectUser={project?.users}
        setProgress={props.setProgress}
      />
      {/* <Notification /> */}
      {isOpenTaskModelWindow && (
        <TaskModal
          isOpenTaskModelWindow={isOpenTaskModelWindow}
          setIsOpenTaskModelWindow={setIsOpenTaskModelWindow}
          handleCreateTask={handleCreateTask}
          projectId={project?.id}
          projectUser={project?.users}
          projectStatuses={project?.statuses}
          projectList={projectTaskLists}
          {...(taskType === "B" && { isTaskType: true })}
          {...(taskType === "E" && { isStory: true })}
          {...(location.pathname ===
            `/user/project/${id}/taskList/${listId}` && { taskListId: listId })}
          isProgress={
            (location.pathname.includes("progress") ||
              location.pathname.includes("viewsprint")) &&
            latestSprint?.id > 0
              ? true
              : false
          }
          setProgress={props.setProgress}
          activeSprint={latestSprint}
        />
      )}

      <ExportProjectModal
        open={isOpenExportProject}
        setOpen={setIsOpenExportProject}
      />
    </div>
  );
}

export default Navbar;

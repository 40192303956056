import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faTrash } from "@fortawesome/free-solid-svg-icons";
import { MenuItem, Select, Autocomplete, TextField, Checkbox, ListItemText } from "@mui/material";
import ClipLoader from "react-spinners/ClipLoader";
import "react-toastify/dist/ReactToastify.css";
import { api } from "../../../../customAxios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import debounce from "lodash.debounce";
import { getItemIconClass } from "../../../../Utils/helpers";

function AddReferenceOrLinkModal(props) {
  const { id } = useParams();
  const {
    openReferenceModal,
    setOpenReferenceModal,
    isReference,
    allReference,
    references,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [workItems, setWorkItems] = useState([]);
  const [searchItems, setSearchItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearch, setIsSearch] = useState(false);

  const handleClose = () => {
    setOpenReferenceModal(false);
    setSearchItems(null);
    setWorkItems([]);
    setIsLoading(false);
  };

  const handleAddItems = async () => {
    setOpenReferenceModal(false);
    allReference(workItems);
  };
  useEffect(() => {
    if (references) {
      setWorkItems(references);
    }
  }, [references]);
  const handleDeselectTestCase = (Id) => {
    const updatedArray = workItems?.filter((item) => item.task_id !== Id);
    setWorkItems(updatedArray);
  };

  const fetchItemsFromApi = async (searchQuery) => {
    setIsSearch(true);
    try {
      const response = await api.get(
        `/project/${id}/task-search?keyword=${searchQuery}`
      );
      setSearchItems(response?.data || []);
    } catch (error) {
      toast.error("Oops! Something went wrong.");
    } finally {
      setIsSearch(false);
    }
  };

  const debouncedSearch = useCallback(
    debounce((query) => fetchItemsFromApi(query), 300),
    []
  );

  useEffect(() => {
    if (searchTerm.trim().length > 0) {
      debouncedSearch(searchTerm);
    } else {
      setSearchItems([]); // Clear items if input is empty
    }
    return () => debouncedSearch.cancel(); // Cleanup debounce
  }, [searchTerm, debouncedSearch]);
  const itemTypes = ["User Story", "Task", "Bug"];
  const handleCheckboxToggle = (item) => {
    const isSelected = workItems?.some((option) => option.task_id === item.task_id);
    if (isSelected) {
      setWorkItems((prev) => prev.filter((workItem) => workItem.task_id !== item.task_id));
    } else {
      setWorkItems((prev) => [...prev, item]);
    }
  };


  return (
    <Modal
      size="lg"
      show={openReferenceModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="pt-5 pl-5 add-member_modal"
    >
      <div className="container">
        <div className="main-modal">
          <Modal.Header className="project-modal_header">
            <Modal.Title className="d-flex align-items-center">
              Add {isReference ? "Reference" : "Link"}
            </Modal.Title>
            <FontAwesomeIcon icon={faClose} onClick={handleClose} />
          </Modal.Header>
          <Modal.Body className="project-modal_body">
            <div className="fields">
              <div className="row">
                <div className="col-md-12 border-black">
                  <div className="d-flex align-items-center gap-4">
                    {!isReference && (
                      <div className="field-box w-25 mt-12">
                        <div className="border-black">
                          <h5 className="text-dark">Item Type</h5>
                          <Select
                            className={`mb-2 mt-5px w-100 inputcontrasts inputSelect`}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            {itemTypes &&
                              itemTypes.map((type, index) => (
                                <MenuItem
                                  key={index}
                                  className="create-task-type"
                                  value={type}
                                >
                                  {type.task_name}
                                </MenuItem>
                              ))}
                          </Select>
                        </div>
                      </div>
                    )}
                    <div
                      className={`field-box ${isReference ? "w-100" : "w-65"}`}
                    >
                      <div className="border-black">
                        <h5 className="text-dark mt-6px">
                          Select {isReference ? "Work Item" : ""}
                        </h5>
                        <Autocomplete
                          options={searchItems}
                          getOptionLabel={(option) => `${option.task_id} - ${option.task_name}`}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={`Search ${isReference ? "work item" : ""}`}
                              variant="outlined"
                              className="mt-5px w-100"
                              onChange={(e) => setSearchTerm(e.target.value)}
                            />
                          )}
                          inputValue={searchTerm} // Set the input value explicitly
                          onInputChange={(event, value) => setSearchTerm(value)} // Update searchTerm based on user input
                          renderOption={(props, option) => {
                            const isSelected = workItems?.some((workItem) => workItem.task_id === option.task_id);
                            return (
                              <li {...props} key={option.task_id} onClick={() => handleCheckboxToggle(option)} className="view-test-case d-flex align-items-center ">
                                <div className="icon ms-3">
                                  <span
                                    className={`${getItemIconClass(option?.task_type, option?.task_parent_id)}
                                      `}
                                  >
                                  </span>
                                </div>
                                <ListItemText
                                  className="text-capitalize ms-2 textEllipse"
                                  primary={
                                    <span>
                                      <span className="text-primary">{option.task_id}</span>
                                      <span className="text-default"> - {option.task_name.length > 50
                                        ? `${option.task_name.substring(0, 50)}...`
                                        : option.task_name}</span>
                                    </span>
                                  }
                                />
                                <Checkbox
                                  checked={isSelected}

                                  className="ms-auto"
                                />
                              </li>
                            );
                          }}
                          noOptionsText="There is no task, please search task and select for reference"
                          loading={isSearch}
                          loadingText="Searching..."
                          isOptionEqualToValue={(option, value) => option.task_id === value.task_id}
                        />

                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-15">
                  {workItems.length > 0 &&
                    workItems.map((item, i) => (
                      <div
                        key={i}
                        className={`${!isReference ? "d-flex align-items-center gap-2" : ""
                          } mb_12`}
                      >
                        {!isReference && (
                          <div className="w-15">
                            <span className="span-gray-color f-14 ms-1">
                              {item.type}
                            </span>
                          </div>
                        )}
                        <div
                          className={`testCase-small-card mb-0 d-flex align-items-center justify-content-between ${!isReference ? "w-85" : "view-test-case"
                            }`}
                        >
                          <div className="d-flex align-items-center w-95">
                            <div className="icon me-2">
                              <span
                                className={`${isReference
                                  ? getItemIconClass(item?.task_type, item?.task_parent_id)
                                  : "text-primary"
                                  }`}
                              >
                                {isReference ? null : item?.task_id}
                              </span>
                            </div>
                            <p className="mb-0 fw-500 text-capitalize d-flex gap-2 textEllipse w-85 align-items-center ">
                              <span className="text-primary">{item?.task_id}</span> - {item.task_name}
                            </p>
                          </div>
                          <span
                            className="float-end add_cursor span-gray-color"
                            onClick={() => handleDeselectTestCase(item.task_id)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </span>
                        </div>

                      </div>
                    ))}
                </div>
              </div>
              <div className="actions">
                <button className="btn btn-cancel btn-sm" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  className={`btn btn-primary btn-sm ${isLoading ? "pd-cre-task-load" : ""
                    }`}
                  disabled={isLoading || workItems.length === 0}
                  onClick={handleAddItems}
                >
                  {isLoading ? (
                    <ClipLoader color="#fff" loading={isLoading} size={20} />
                  ) : isReference ? (
                    "Add Reference"
                  ) : (
                    "Add Items"
                  )}
                </button>
              </div>
            </div>
          </Modal.Body>
        </div>
      </div>
    </Modal>
  );
}

export default AddReferenceOrLinkModal;
